<template>
  <svg
    ref="svgElementRef"
    class="key-figure-chart"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      v-for="(keyFigure, index) in keyFigures"
      :key="`key-figure-circle-${index}`"
      v-element-hover="(state: boolean) => onFigureHover(state, index)"
    >
      <rect
        fill="black"
        fill-opacity="0"
        :x="xScaleUnpadded(keyFigure.keyFigureLabel ?? '')"
        :y="0"
        :width="xScaleUnpadded.bandwidth()"
        :height="height"
      />

      <rect
        fill="#F4F4F4"
        fill-opacity="0.06"
        :x="xScale(keyFigure.keyFigureLabel ?? '')"
        :y="0"
        :width="xScale.bandwidth()"
        :height="height"
      />

      <rect
        class="bar"
        fill="url(#keyFigureChartGradient)"
        :x="xScale(keyFigure.keyFigureLabel ?? '')"
        :y="height - yScale(keyFigure.keyFigureValue ?? 0)"
        :width="xScale.bandwidth()"
        :height="0"
        :opacity="
          keyFigure ===
          (hoveringFigure ?? keyFigures[props.keyFigures.length - 1])
            ? 1
            : 0.4
        "
      />
    </g>

    <defs>
      <linearGradient
        id="keyFigureChartGradient"
        x1="50%"
        y1="7%"
        x2="50%"
        y2="100%"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#97FA5A" />
        <stop offset="1" stop-color="#97FA5A" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script setup lang="ts">
import { easeCubicInOut, scaleBand, scaleLinear, select } from 'd3';
import { vElementHover } from '@vueuse/components';
import type { ArticleCardFragment } from '#gql';

export type KeyFigure = ArticleCardFragment['cardKeyFigures'][number];

const props = defineProps<{
  keyFigures: KeyFigure[];
  width: number;
  height: number;
}>();

const svgElementRef = ref<SVGElement>();

const emit = defineEmits(['active-figure-changed']);

const margin = { top: 10, right: 0, bottom: 10, left: 0 };

const maxValue = Math.max(
  ...props.keyFigures.map((k) => k.keyFigureValue ?? 0),
);

const xScale = computed(() =>
  scaleBand()
    .domain(props.keyFigures.map((k) => k.keyFigureLabel ?? ''))
    .range([margin.left, props.width - margin.right])
    .paddingInner(0.1),
);

const xScaleUnpadded = computed(() => xScale.value.copy().paddingInner(0));

const yScale = computed(() =>
  scaleLinear()
    .domain([0, maxValue])
    .range([props.height - margin.bottom, margin.top]),
);

const hoveringFigure = ref<KeyFigure | null>(null);

function onFigureHover(state: boolean, index: number) {
  hoveringFigure.value = state ? props.keyFigures[index] : null;
  emit(
    'active-figure-changed',
    hoveringFigure.value ?? props.keyFigures[props.keyFigures.length - 1],
  );
}

function startTransition() {
  if (!svgElementRef.value) {
    return;
  }

  const $svg = select(svgElementRef.value!);

  $svg
    .selectAll('.bar')
    .interrupt()
    .attr('y', () => {
      return props.height;
    })
    .attr('height', 0)
    .transition()
    .ease(easeCubicInOut)
    .duration(500)
    .delay((_, index) => index * 200)
    .attr('y', (_, index) => {
      return yScale.value(props.keyFigures[index].keyFigureValue ?? 0);
    })
    .attr('height', (_, index) => {
      return (
        props.height - yScale.value(props.keyFigures[index].keyFigureValue ?? 0)
      );
    });
}

function resetTransition() {
  if (!svgElementRef.value) {
    return;
  }

  const $svg = select(svgElementRef.value!);
  $svg
    .selectAll('.bar')
    .interrupt()
    .attr('y', () => {
      return props.height;
    })
    .attr('height', 0);
}

defineExpose({
  startTransition,
  resetTransition,
});
</script>

<style scoped lang="scss">
.key-figure-chart {
  overflow: visible;
}
</style>
