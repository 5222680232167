<template>
  <NuxtLink
    class="article-card"
    :class="[`-type-${articleCard.cardType}`]"
    :to="getRoute(articleCard.translatedSlug ?? '?', 'ArticleRecord')"
  >
    <div class="header">
      <div class="type">
        {{ articleCard.articleType?.label }}
      </div>
      <div class="title">
        {{ articleCard.cardTitle }}
      </div>
    </div>

    <div class="project-label">{{ $t('project.title') }}</div>

    <div ref="cardContentElementRef" class="content">
      <div
        v-if="
          articleCard.cardType !== 'project' &&
          (articleCard.cardShowFacts ||
            articleCard.cardSmallShowFactsOrFigure === 'facts' ||
            articleCard.cardShowKeyFigure ||
            articleCard.cardSmallShowFactsOrFigure === 'figure')
        "
        class="wrapper"
      >
        <div
          v-if="
            articleCard.cardShowFacts ||
            articleCard.cardSmallShowFactsOrFigure === 'facts'
          "
          class="facts"
        >
          <div v-if="articleCard.cardFactsLine1" class="first">
            {{ articleCard.cardFactsLine1 }}
          </div>
          <div v-if="articleCard.cardFactsLine2" class="second">
            {{ articleCard.cardFactsLine2 }}
          </div>
        </div>

        <div
          v-if="
            articleCard.cardShowKeyFigure ||
            articleCard.cardSmallShowFactsOrFigure === 'figure'
          "
          class="figure"
        >
          <span class="value">{{
            countingFigure ?? articleCard.cardKeyFigure
          }}</span>
          <span class="suffix">{{ articleCard.cardKeyFigureSuffix }}</span>
        </div>
      </div>

      <div v-if="articleCard.cardType === 'chart'" class="chart">
        <KeyFigureChart
          ref="keyFigureChartComponentRef"
          class="keyfigurechart"
          :key-figures="articleCard.cardKeyFigures ?? []"
          :width="343"
          :height="157"
          @active-figure-changed="activeFigure = $event"
        />
        <div class="value text-sm">
          <span class="label">{{ activeFigure?.keyFigureLabel }}</span>
          <div class="figure">
            <span class="value">{{
              countingFigure ?? activeFigure?.keyFigureValue
            }}</span>
            <span class="suffix">{{ articleCard.cardKeyFiguresSuffix }}</span>
          </div>
        </div>
      </div>

      <ProjectProgress
        v-if="articleCard.cardType === 'project'"
        class="project text-lg"
        :progress="articleCard.cardProjectProgress ?? 'ongoing'"
      />
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { ArticleCardFragment } from '#gql';
import { useInternalRoute } from '~/datocms/useInternalRoute';
import KeyFigureChart, {
  type KeyFigure,
} from '~/components/article/KeyFigureChart.vue';
import ProjectProgress from '~/components/article/ProjectProgress.vue';

const props = defineProps<{
  articleCard: ArticleCardFragment;
}>();

const chartCardActiveFigure = ref<KeyFigure | null>(
  props.articleCard.cardType === 'chart'
    ? props.articleCard.cardKeyFigures[
        props.articleCard.cardKeyFigures.length - 1
      ]
    : null,
);
const activeFigure = ref<KeyFigure | null>(chartCardActiveFigure.value);

const { getRoute } = useInternalRoute();

const countingFigure = ref<number | null>(0);

const cardContentElementRef = ref<HTMLElement>();
const keyFigureChartComponentRef = ref();
const isCardVisible = useElementVisibility(cardContentElementRef);

watch(isCardVisible, async () => {
  if (!isCardVisible.value) {
    countingFigure.value = 0;
    if (keyFigureChartComponentRef.value) {
      keyFigureChartComponentRef.value.resetTransition();
    }
    return;
  }

  setTimeout(() => {
    const figure =
      props.articleCard.cardKeyFigure ?? activeFigure.value?.keyFigureValue;
    if (figure) {
      animateCounter(0, figure);
      if (keyFigureChartComponentRef.value) {
        keyFigureChartComponentRef.value.startTransition();
      }
    }
  }, 200);
});

function animateCounter(start: number, end: number) {
  const startTime = performance.now();
  const numberOfDecimalPlaces = end.toString().split('.')[1]?.length ?? 0;

  const update = (currentTime: number) => {
    const elapsedTime = currentTime - startTime;
    const progress = Math.min(elapsedTime / 500, 1);

    const roundingPlaces = Math.pow(10, numberOfDecimalPlaces);
    countingFigure.value =
      Math.round(start + (end - start) * progress * roundingPlaces) /
      roundingPlaces;

    if (progress < 1) {
      requestAnimationFrame(update);
    } else {
      countingFigure.value = null;
    }
  };

  requestAnimationFrame(update);
}
</script>

<style scoped lang="scss">
.article-card {
  @extend %card-background-with-border;

  --local-title-spacing: var(--spacing-md);

  display: grid;
  min-height: 100%;
  width: 100%;
  text-decoration: none;

  padding: var(--local-card-padding);

  @media (--vs) {
    --local-title-spacing: 0.5rem;
    --article-card-suffix-margin: 0.1em;

    --local-card-padding: 1.75rem 1rem;
  }

  @media (--vl) {
    --local-title-spacing: 1.5rem;
    --article-card-suffix-margin: 0.3em;

    --local-card-padding: 2rem 2.5rem;
  }

  > .header {
    > .type {
      @extend .text-sm-vs;
      @extend .text-md-vl;

      padding-bottom: var(--local-title-spacing);
      color: var(--card-color-faded);

      @media (--vs) {
        line-height: 125%;
      }
    }

    > .title {
      @extend .text-lg-vs;
      @extend .text-lg-vl;

      hyphens: auto;
      text-wrap: pretty;

      // Hack
      :deep(em) {
        font-style: normal;
      }
    }
  }

  > .project-label {
    position: absolute;
    right: 0;
    padding: var(--local-card-padding);

    @extend .text-sm-vs;
    @extend .text-md-vl;

    color: var(--card-color-faded);
  }

  > .content {
    margin-top: auto;
  }

  > .content > .wrapper {
    display: flex;
    column-gap: 1.5rem;
    row-gap: 0.5rem;

    > .facts {
      color: var(--card-color-faded);

      > .first {
        @media (--vs) {
          font-size: 0.875rem;
          line-height: 185%;
        }

        @media (--vl) {
          font-size: 1rem;
          line-height: 125%;
        }
      }

      > .second {
        @media (--vs) {
          font-size: 1.25rem;
          line-height: 125%;
        }

        @media (--vl) {
          font-size: 1.5rem;
          line-height: 125%;
        }
      }
    }

    &:has(> .figure) {
      justify-content: end;
      align-items: end;

      > .facts {
        text-align: right;
      }
    }

    &:not(:has(> .figure)) > .facts {
      margin-top: 1rem;
    }

    > .figure {
      display: flex;
      align-items: start;
      justify-content: end;
      text-align: right;

      > .value {
        @extend .text-5xl;

        color: var(--base-color-brand);
        position: relative;
        display: inline-block;

        // Trump specificity...
        &.value {
          line-height: 70%;

          @media (--vs) {
            font-size: 4rem;
          }

          @media (--vl) {
            font-size: 6rem;
          }
        }

        &::before {
          position: absolute;
          display: block;
          content: '';
          inset: -0.75rem -1.5rem;
          opacity: 0.7;
          background: var(--color-lime-400);
          filter: blur(5rem);
        }
      }

      > .suffix {
        padding-left: 0.25ch;
        color: var(--base-color-brand);
        margin-top: var(--article-card-suffix-margin);
        font-size: 1.5rem;
        line-height: 70%;
      }
    }
  }

  &:not(.-type-project) {
    > .project-label {
      display: none;
    }
  }

  &.-type-project {
    > .content {
      > .project {
        &::before {
          position: absolute;
          display: block;
          content: '';
          top: -1em;
          right: -1em;
          width: 3em;
          height: 3em;
          opacity: 0.7;
          background: var(--color-lime-400);
          filter: blur(5rem);
        }
      }
    }
  }

  &.-type-chart {
    > .header > .title {
      @extend .text-lg-vl;
    }

    > .content > .chart {
      display: grid;
      align-items: end;

      @media (--vs) {
        grid-template-columns: 185fr 143fr;
        gap: 1.5rem;
      }

      @media (--vl) {
        grid-template-columns: 343fr 193fr;
        gap: 2.5rem;
      }

      > .value {
        text-align: right;
        color: var(--base-color-foreground-faded);

        @media (--vl) {
          padding-right: 1.062rem;
        }

        > .label {
          display: block;

          @media (--vs) {
            font-size: 0.75rem;
            line-height: 125%;
          }

          @media (--vl) {
            font-size: 1rem;
            line-height: 160%;
          }
        }

        > .figure {
          display: flex;
          align-items: start;
          justify-content: end;

          > .value {
            @extend .text-5xl;

            color: var(--base-color-brand);
            position: relative;
            display: inline-block;

            &::before {
              position: absolute;
              display: block;
              content: '';
              inset: -0.75rem -1.5rem;
              opacity: 0.7;
              background: var(--color-lime-400);
              filter: blur(5rem);
            }
          }

          > .suffix {
            padding-left: 0.25ch;
            color: var(--base-color-brand);
            margin-top: var(--article-card-suffix-margin);
          }
        }
      }
    }
  }

  &.-view-list {
    @media (--vs) {
      &:has(.figure) {
        > .content > .wrapper {
          margin-top: 0.75rem;
        }
      }

      &:not(:has(.figure)) {
        > .content > .wrapper > .facts {
          margin-left: 0;
          text-align: left;
        }
      }
    }

    &.-type-project {
      > .content > .project {
        @extend .text-xs-vs;

        &::before {
          content: none;
        }
      }
    }
  }

  &:not(.-view-list) {
    @media (--vs) {
      --article-card-title-line-clamp: 3;

      height: 13.5rem;

      &.-type-chart {
        height: 18.5rem;
      }
    }

    @media (--vl) {
      --article-card-title-line-clamp: 5;

      height: 24rem;
    }

    > .header > .title {
      display: -webkit-box;
      -webkit-line-clamp: var(--article-card-title-line-clamp);
      -webkit-box-orient: vertical;
      overflow: hidden;
      //height: calc(var(--article-card-title-line-clamp) * 1lh);
    }

    &.-type-highlight,
    &.-type-big,
    &.-type-project {
      @media (--vl) {
        --article-card-title-line-clamp: 4;
      }
    }

    &.-type-chart {
      @media (--vl) {
        --article-card-title-line-clamp: 3;
      }
    }
  }

  &:not(.-view-list).-type-small.-expanded,
  &.-type-big,
  &.-type-project {
    > .header > .title {
      @extend .text-2xl-vl;
    }
  }

  &.-type-highlight {
    > .header > .type {
      color: #ffffff80;
    }

    > .header > .title {
      @extend .text-2xl-vl;
    }
  }

  // List View
  &.-view-list {
    width: 100%;

    @media (--vs) {
      --local-title-spacing: 0.25rem;

      gap: 0.5rem;

      &:has(.content:empty) {
        gap: 0;
      }

      //&:not(:has(.content:empty)) {
      > .header > .title {
        //max-width: 70vw;
        font-size: 1rem;
        line-height: 160%;
      }
      //}

      > .content > .wrapper {
        > .figure {
          > .value {
            font-size: 2.5rem;
            line-height: 0.85;
          }
        }
      }

      > .content > .chart > .value {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        font-size: 14px;
        line-height: 1.3;
        gap: 1.5rem;

        > .figure {
          > .value {
            font-size: 2.5rem;
            line-height: 0.85;
          }
        }
      }
    }

    @media (--vl) {
      --local-title-spacing: 0.75rem;

      grid-template-columns: 2fr 1fr;
    }

    > .header > .title.title {
      @extend .text-lg-vl;
    }

    > .content > .wrapper {
      margin-top: 0;
      min-height: 100%;
      align-items: end;

      &:not(:has(> .figure)) > .facts {
        margin-left: auto;
      }

      > .facts {
        text-align: right;
      }
    }

    &.-type-highlight > .header > .title.title {
      @extend .text-xl-vl;
    }

    &.-type-chart {
      > .content > .chart {
        grid-template-columns: 1fr;

        > .keyfigurechart {
          display: none;
        }
      }
    }
  }

  // Animations
  //@media (prefers-reduced-motion: no-preference) {
  //  @keyframes fade-in {
  //    to {
  //      opacity: 1;
  //      transform: translateY(0);
  //    }
  //  }
  //
  //  &[data-in-viewport] {
  //    opacity: 0;
  //    transform: translateY(2rem);
  //
  //    &.-was-in-viewport {
  //      animation: fade-in var(--base-transition-duration-slow)
  //        var(--base-transition-easing) forwards;
  //    }
  //  }
  //}
}
</style>
