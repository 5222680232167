<template>
  <section class="article-list">
    <ControlPanel
      :filter="filter"
      :view="selectedArticleView"
      :article-types="articleTypes"
      :topics="
        topics.filter((t) => (t._allReferencingArticlesMeta?.count ?? 0) > 0)
      "
    />
    <div class="list" :class="`-view-${selectedArticleView}`">
      <article
        v-for="articleCard in filteredArticleCards"
        :key="articleCard.id"
        class="item"
        :class="{
          [`-type-${articleCard.cardType}`]: true,
          '-expanded': expandedSmallViews.has(articleCard.id),
        }"
      >
        <ArticleCard
          :class="{
            [`-view-${selectedArticleView}`]: true,
            [`-type-${articleCard.cardType}`]: true,
            '-expanded': expandedSmallViews.has(articleCard.id),
          }"
          :article-card="articleCard"
        />
      </article>
    </div>
  </section>
</template>

<script setup lang="ts">
import type {
  ArticleCardFragment,
  ArticleTopicFragment,
  ArticleTypeFragment,
} from '#gql';
import ControlPanel, {
  type ArticleFilter,
  type ArticleViewModel,
} from '~/components/article/ControlPanel.vue';

const props = defineProps<{
  articleCards: ArticleCardFragment[];
  articleTypes: ArticleTypeFragment[];
  topics: ArticleTopicFragment[];
}>();

const route = useRoute();

const selectedArticleView = computed(
  () => ((route.query.view as string) ?? 'card') as ArticleViewModel,
);
const filter = computed<ArticleFilter>(() => ({
  articleType:
    props.articleTypes.find((t) => t.translatedSlug === route.params.slug) ??
    null,
  topic:
    props.topics.find((t) => t.translatedSlug === route.params.topicSlug) ??
    null,
}));

const filteredArticleCards = computed(() => {
  const filterTypeKey = filter.value?.articleType?.technicalKey;
  const filterTopicId = filter.value?.topic?.id;

  return props.articleCards.filter((articleCard) => {
    if (
      filter.value?.articleType &&
      articleCard.articleType?.technicalKey !== filterTypeKey
    ) {
      return false;
    }

    return !(
      filter.value?.topic &&
      !(articleCard.topics ?? []).some((t) => t.id === filterTopicId)
    );
  });
});

const NUMBER_OF_COLUMNS = 4; // TODO: may be not fixed to 4
const expandedSmallViews = computed(() => {
  let currentRow: ArticleCardFragment[] = [];
  const ids = new Set<string>();

  filteredArticleCards.value.forEach((articleCard) => {
    const currentRowColumnCount = currentRow.reduce(
      (sum, ac) => sum + cardCols(ac),
      0,
    );

    const nextCardColumnCount = cardCols(articleCard);
    if (currentRowColumnCount + nextCardColumnCount === NUMBER_OF_COLUMNS) {
      // card has just fitted the row, start an empty next row
      currentRow = [];
    } else if (
      currentRowColumnCount + nextCardColumnCount >
      NUMBER_OF_COLUMNS
    ) {
      // card does not fit the row: find first small article and mark add it to the set of articles that must be expanded
      const first = currentRow.find((ac) => ac.cardType === 'small');
      if (first) {
        ids.add(first.id);
      }

      // as card dit not fit the current row, move it to next row
      currentRow = [articleCard];
    } else {
      // row not full, add card to current row and proceed
      currentRow.push(articleCard);
    }
  });

  return ids;
});

function cardCols(card: ArticleCardFragment): 1 | 2 {
  return card.cardType === 'small' ? 1 : 2;
}
</script>

<style scoped lang="scss">
.article-list {
  @media (--vs) {
    padding-inline: 0.5rem;
    padding-bottom: 4rem;
  }

  @media (--vl) {
    padding-inline: 4rem;
    padding-bottom: 4rem;
  }

  > .list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.25rem;

    &.-view-card {
      @media (--vl) {
        gap: 1.5rem;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-flow: row;

        //@media (min-width: 1200px) {
        //  grid-template-columns: repeat(4, 1fr);
        //}
      }
    }
  }

  > .list.-view-card > .item {
    // Comment in for "debugging" extended small views
    //&.-type-small.-expanded {
    //  border: 1px solid red;
    //}

    &.-type-small.-expanded,
    &.-type-chart,
    &.-type-highlight,
    &.-type-big,
    &.-type-project {
      @media (--vl) {
        grid-column: span 2;
      }
    }
  }
}
</style>
